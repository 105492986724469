import React from 'react';
import { toast } from 'react-toastify';
import LoadingIcon from '../components/common/LoadingIcon';

export const getToastPropsBy = ({ icon, title, text }) => ({
  toastOptions: {
    icon: <img src={icon} height={35} width={35} />,
  },
  toastBody: (
    <>
      <h4>{title}</h4>
      <p>{text}</p>
    </>
  ),
});

export const getLoadingToastPropsBy = (text) => ({
  toastOptions: {
    icon: LoadingIcon,
    type: toast.TYPE.INFO,
    theme: 'colored',
  },
  toastBody: (
    <>
      <h4>Hang on</h4>
      <p>{text}</p>
    </>
  ),
});

export const getSuccesToastPropsBy = ({ title, text }) => {
  const { toastBody, toastOptions } = getToastPropsBy({
    icon: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f389.png',
    title,
    text,
  });

  return {
    render: toastBody,
    icon: toastOptions.icon,
    type: toast.TYPE.SUCCESS,
    theme: 'colored',
    isLoading: false,
    autoClose: 2000,
  };
};
export const getErrorUpdateToastPropsBy = ({ title, text }) => {
  const { toastBody, toastOptions } = getToastPropsBy({
    icon: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/274c.png',
    title,
    text,
  });

  return {
    render: toastBody,
    icon: toastOptions.icon,
    type: toast.TYPE.ERROR,
    theme: 'colored',
    isLoading: false,
    autoClose: 2000,
  };
};

export const showRequestFailedToast = () => {
  const { toastBody, toastOptions } = getToastPropsBy({
    icon: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f648.png',
    title: 'Something went wrong',
    theme: 'colored',
    text: 'Please contact an administrator if the problem keeps persisting',
  });
  toast.error(toastBody, toastOptions);
};

export const showRequestFailedToastWith = ({ text, title }) => {
  const { toastBody, toastOptions } = getToastPropsBy({
    icon: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f644.png',
    title: title,
    theme: 'colored',
    text: text,
  });
  toast.error(toastBody, toastOptions);
};

export const getValidationErrorToastPropsBy = (text) =>
  getToastPropsBy({ icon: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f648.png', title: 'Something went wrong', text });

export const SAVE_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Saving your timesheet...'),
  success: getSuccesToastPropsBy({ title: 'Saving complete', text: 'Saving was successful!' }),
  error: getErrorUpdateToastPropsBy({
    title: 'Saving failed',
    text: "We couldn't save your timesheet, try again later!",
  }),
};

export const SUBMIT_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Submitting your timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Submitting complete',
    text: 'Timesheet sent to timesheet approver successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Submitting failed',
    text: "We couldn't submit your timesheet, try again later!",
  }),
};

export const UPLOAD_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Uploading your timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Uploading complete',
    text: 'Timesheet has been uploaded successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Uploading failed',
    text: "We couldn't upload your timesheet, try again later!",
  }),
};

export const DOWNLOAD_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Downloading your timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Download complete',
    text: 'Timesheet has been downloaded successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Download failed',
    text: "We couldn't handle your timesheet download request, try again later!",
  }),
};

export const DOWNLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Downloading the timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Download complete',
    text: 'Timesheet has been downloaded successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Download failed',
    text: "We couldn't handle your timesheet download request, try again later!",
  }),
};

export const UPLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Uploading your timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Upload complete',
    text: 'Timesheet has been uploaded successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Upload failed',
    text: "We couldn't handle your timesheet upload request, try again later!",
  }),
};

export const DOWNLOAD_DOCUMENT_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Downloading the selected document...'),
  success: getSuccesToastPropsBy({
    title: 'Download complete',
    text: 'Document has been downloaded successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Download failed',
    text: "We couldn't handle your document download request, try again later!",
  }),
};

export const APPROVE_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Approving the timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Approval complete',
    text: 'Timesheet has been approved successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Approval failed',
    text: "We couldn't approve the timesheet, try again later!",
  }),
};

export const REJECT_TIMESHEET_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Rejecting the timesheet...'),
  success: getSuccesToastPropsBy({
    title: 'Rejection complete',
    text: 'Timesheet has been rejected successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Rejection failed',
    text: "We couldn't reject the timesheet, try again later!",
  }),
};

export const UPDATE_SELECTED_MILESTONES_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Updating the selected milestones...'),
  success: getSuccesToastPropsBy({
    text: 'Selected milestones have been updated successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Update selected milestones failed',
    text: "We couldn't update the selected milestones, try again later!",
  }),
};

export const DELETE_USERTRACK_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Deleting the selected usertrack...'),
  success: getSuccesToastPropsBy({
    text: 'The selected usertrack has been deleted successfully!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Delete selected usertrack failed',
    text: "We couldn't delete the selected usertrack, try again later!",
  }),
};

export const COMPLETE_USERTRACK_TOAST_MESSAGES = {
  pending: getLoadingToastPropsBy('Completing the usertrack...'),
  success: getSuccesToastPropsBy({
    text: 'The usertrack has been successfully set to completed!',
  }),
  error: getErrorUpdateToastPropsBy({
    title: 'Usertrack completion failed',
    text: "We couldn't set the usertrack to completed, try again later!",
  }),
}