import { saveAs } from 'file-saver';
class FileService {
  handleFileDownload(response, documentName) {
    if (response) {
      var fileName = documentName ? documentName : getFileNameFromHeaders(response.headers);
      saveAs(response.data, fileName);
    }
  }
}
export default new FileService();

function getFileNameFromHeaders(headers) {
  var fileName = '';
  var contentDisposition = headers['content-disposition'];
  var idx = contentDisposition.indexOf('filename=');
  if (idx >= 0) {
    var endIdx = contentDisposition.indexOf(';', idx);
    fileName = contentDisposition.substring(idx + 9, endIdx);
  }

  fileName = fileName.replace(/"/g, '');
  return fileName;
}
