import PropTypes from 'prop-types';
import React, { memo, useMemo, useCallback } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';

function MenuItem({ to, text, exact, icon, iconGradient, external }) {
  const isActivePage = useRouteMatch({ path: to, exact });
  const history = useHistory();
  
  const handleNavigate = useCallback(
  () => (external ? (window.open(to, '_blank')) : history.push(to)),
  [external, history, to]
);

  const iconClasses = useMemo(
    () => `${icon}${iconGradient ? ` icon-gradient ${iconGradient}` : ''}`,
    [icon, iconGradient]
  );

  return (
    <li className={`sidebar-menu--item ${isActivePage && 'active'}`} onClick={handleNavigate}>
      {external ? (
        <a>
          <i className={iconClasses} />
          <span className="sidebar-menu--item-text">{text}</span>
        </a>
      ) : (
        <NavLink to={to} exact={exact}>
          <i className={iconClasses} />
          <span className="sidebar-menu--item-text">{text}</span>
        </NavLink>
      )}
    </li>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  external: PropTypes.bool,
  exact: PropTypes.bool,
  icon: PropTypes.string,
  iconGradient: PropTypes.string,
  openNewTab: PropTypes.bool
};

export default memo(MenuItem);
