import api from './baseApi';

export function getDocuments(params) {
  return api.get('/documents/tenantDocuments', params);
}

export function getSasUriForBlob(documentId) {
  return api.get(`/documents/admin/sasUri/azureBlobStorageSasUri/${documentId}`);
}

export function downloadDocument(uri) {
  return api.getFileFormBlobStorage(uri);
}
