import {
  approveTimesheet as handleApproveTimesheet,
  downloadTimesheet as handleDownloadTimesheet,
  downloadValidatedTimesheet as handleDownloadValidatedTimesheet,
  getTimesheet,
  getTimesheetByDate,
  getTimesheetsByContractId,
  getTimesheetsByUserId,
  saveTimesheet as handleSaveTimesheet,
  submitTimesheet as handleSubmitTimesheet,
  updateTemporaryTimesheetSigner,
  uploadTimesheet as handleUploadTimesheet
} from '../../api/timesheetsApi';
import fileService from '../../service/fileService';
import { toast } from 'react-toastify';
import {
  APPROVE_TIMESHEET_TOAST_MESSAGES,
  DOWNLOAD_TIMESHEET_TOAST_MESSAGES,
  DOWNLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES,
  SAVE_TIMESHEET_TOAST_MESSAGES,
  showRequestFailedToast,
  showRequestFailedToastWith,
  SUBMIT_TIMESHEET_TOAST_MESSAGES,
  UPLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES
} from '../../service/toastService';
import { getHolidays } from '../../api/holidaysApi';
import { cloneDeep } from 'lodash';

export const FETCH_TIMESHEETS = 'FETCH_TIMESHEETS';
export const FETCH_TIMESHEETS_SUCCESS = 'FETCH_TIMESHEETS_SUCCESS';
export const FETCH_TIMESHEETS_FAILED = 'FETCH_TIMESHEETS_FAILED';
export const FETCH_TIMESHEET = 'FETCH_TIMESHEET';
export const FETCH_TIMESHEET_SUCCESS = 'FETCH_TIMESHEET_SUCCESS';
export const FETCH_TIMESHEET_FAILED = 'FETCH_TIMESHEET_FAILED';
export const FETCH_TIMESHEET_BY_DATE = 'FETCH_TIMESHEET_BY_DATE';
export const FETCH_TIMESHEET_BY_DATE_SUCCESS = 'FETCH_TIMESHEET_BY_DATE_SUCCESS';
export const FETCH_TIMESHEET_BY_DATE_FAILED = 'FETCH_TIMESHEET_BY_DATE_FAILED';
export const ADD_ENTRY = 'ADD_ENTRY';
export const REMOVE_ENTRY = 'REMOVE_ENTRY';
export const CANCEL_TIMESHEET_EDITING = 'CANCEL_TIMESHEET_EDITING';
export const SET_ENTRY_KEY = 'SET_ENTRY_KEY';
export const SET_ENTRY_AMOUNT = 'SET_ENTRY_AMOUNT';
export const SET_ENTRY_REMARKS = 'SET_ENTRY_REMARKS';
export const SET_ENTRY_PROJECTID = 'SET_ENTRY_PROJECTID';
export const SAVE_TIMESHEET = 'SAVE_TIMESHEET';
export const SAVE_TIMESHEET_SUCCESS = 'SAVE_TIMESHEET_SUCCESS';
export const SAVE_TIMESHEET_FAILED = 'SAVE_TIMESHEET_FAILED';
export const SUBMIT_TIMESHEET = 'SUBMIT_TIMESHEET';
export const SUBMIT_TIMESHEET_SUCCESS = 'SUBMIT_TIMESHEET_SUCCESS';
export const SUBMIT_TIMESHEET_FAILED = 'SUBMIT_TIMESHEET_FAILED';
export const APPROVE_OWN_TIMESHEET = 'APPROVE_OWN_TIMESHEET';
export const APPROVE_OWN_TIMESHEET_SUCCESS = 'APPROVE_OWN_TIMESHEET_SUCCESS';
export const APPROVE_OWN_TIMESHEET_FAILED = 'APPROVE_OWN_TIMESHEET_FAILED';
export const DOWNLOAD_TIMESHEET = 'DOWNLOAD_TIMESHEET';
export const DOWNLOAD_VALIDATED_TIMESHEET = 'DOWNLOAD_VALIDATED_TIMESHEET';
export const DOWNLOAD_TIMESHEET_SUCCESS = 'DOWNLOAD_TIMESHEET_SUCCESS';
export const DOWNLOAD_TIMESHEET_FAILED = 'DOWNLOAD_TIMESHEET_FAILED';
export const UPLOAD_TIMESHEET = 'UPLOAD_TIMESHEET';
export const UPLOAD_TIMESHEET_SUCCESS = 'UPLOAD_TIMESHEET_SUCCESS';
export const UPLOAD_TIMESHEET_FAILED = 'UPLOAD_TIMESHEET_FAILED';
export const AUTOCOMPLETE_TIMESHEET = 'AUTOCOMPLETE_TIMESHEET';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const REMOVE_EXPENSE = 'REMOVE_EXPENSE';
export const SHOW_EXPENSE_MODAL = 'SHOW_EXPENSE_MODAL';
export const SET_EXPENSE_KEY = 'SET_EXPENSE_KEY';
export const SET_EXPENSE_AMOUNT = 'SET_EXPENSE_AMOUNT';
export const SET_EXPENSE_REMARK = 'SET_EXPENSE_REMARK';
export const SET_ENTRY_HOMEWORK = 'SET_ENTRY_HOMEWORK';
export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILED = 'FETCH_HOLIDAYS_FAILED';
export const SET_TEMP_TIMESHEET_SIGNER = 'SET_TEMP_TIMESHEET_SIGNER';
export const SET_TEMP_TIMESHEET_SIGNER_SUCCESS = 'SET_TEMP_TIMESHEET_SIGNER_SUCCESS';
export const SET_TEMP_TIMESHEET_SIGNER_FAILED = 'SET_TEMP_TIMESHEET_SIGNER_FAILED';

let currentToast = null;

export function fetchTimesheetsByUserId(userId, managingOtherUser, skip, take) {
  return async (dispatch) => {
    dispatch({ type: FETCH_TIMESHEETS });
    try {
      const response = await getTimesheetsByUserId(userId, { managingOtherUser, skip, take });
      return dispatch({
        type: FETCH_TIMESHEETS_SUCCESS,
        timesheets: response.data.timesheets,
        totalCount: response.data.totalCount,
        contractsHaveTimesheetForCurrentMonth: response.data.contractsHaveTimesheetForCurrentMonth,
        skip,
        take
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_TIMESHEETS_FAILED, error });
    }
  };
}

export function fetchTimesheetsByContractId(contractId) {
  return async (dispatch) => {
    dispatch({ type: FETCH_TIMESHEETS });
    try {
      const response = await getTimesheetsByContractId(contractId);
      return dispatch({ type: FETCH_TIMESHEETS_SUCCESS, timesheets: response.data.timesheets });
    } catch (error) {
      dispatch({ type: FETCH_TIMESHEETS_FAILED, error });
      showRequestFailedToast();
    }
  };
}

export function fetchTimesheet(timesheetId, contractId, company) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_TIMESHEET });
    try {
      const response = await getTimesheet(timesheetId, contractId, company);
      return dispatch({
        type: FETCH_TIMESHEET_SUCCESS,
        timesheet: response.data.timesheet,
        currentUser: getState().auth.user
      });
    } catch (error) {
      dispatch({ type: FETCH_TIMESHEET_FAILED, error });
      showRequestFailedToast();
    }
  };
}

export function fetchTimesheetByDate(year, month, contractId, company) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_TIMESHEET });
    try {
      const response = await getTimesheetByDate(year, month, contractId, company);
      return dispatch({
        type: FETCH_TIMESHEET_SUCCESS,
        timesheet: response.data.timesheet,
        currentUser: getState().auth.user
      });
    } catch (error) {
      dispatch({ type: FETCH_TIMESHEET_FAILED, error });
      showRequestFailedToastWith({
        title: 'Failed to create timesheet',
        text: 'The timesheet was not created, try again later!'
      });
    }
  };
}

export function addEntry(timesheet, dayIndex, isFreeText) {
  return { type: ADD_ENTRY, timesheet, dayIndex, isFreeText };
}

export function removeEntry(timesheet, dayIndex, entryIndex) {
  return { type: REMOVE_ENTRY, timesheet, dayIndex, entryIndex };
}

export function cancelTimesheetEditing(pristineTimesheet) {
  return { type: CANCEL_TIMESHEET_EDITING, pristineTimesheet };
}

export function setEntryKey(timesheet, dayIndex, entryIndex, key, isFreeText) {
  return { type: SET_ENTRY_KEY, timesheet, dayIndex, entryIndex, key, isFreeText };
}

export function setEntryAmount(timesheet, dayIndex, entryIndex, amount) {
  return { type: SET_ENTRY_AMOUNT, timesheet, dayIndex, entryIndex, amount };
}

export function setEntryRemarks(timesheet, dayIndex, entryIndex, remarks) {
  return { type: SET_ENTRY_REMARKS, timesheet, dayIndex, entryIndex, remarks };
}

export function setEntryProjectId(timesheet, dayIndex, entryIndex, projectId) {
  return { type: SET_ENTRY_PROJECTID, timesheet, dayIndex, entryIndex, projectId };
}

export function saveTimesheet(timesheet) {
  return function(dispatch, getState) {
    dispatch({ type: SAVE_TIMESHEET });
    currentToast = toast.loading(
      SAVE_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      SAVE_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    const existingError =
      timesheet.days.some((entry) => entry.entries[0].amount > 24 || entry.entries[0].amount < 0) ||
      timesheet.days.some((day) => day.entries.some((entry) => entry.expenses.some((expense) => expense.hasError)));
    if (!existingError) {
      const projects = getState().projects.projectsList;
      const enrichedTimesheet = enrichEntriesWithProjectNamesWhenApplicable(timesheet, projects);

      return handleSaveTimesheet(enrichedTimesheet)
        .then(() => {
          // setTimeout(() => toast.update(currentToast, SAVE_TIMESHEET_TOAST_MESSAGES.success), 1000);
          // Toast doesn't update color if the update happens to fast, shouldn't be a problem
          toast.update(currentToast, SAVE_TIMESHEET_TOAST_MESSAGES.success);
          return dispatch({
            type: SAVE_TIMESHEET_SUCCESS,
            timesheet: timesheet,
            currentUser: getState().auth.user,
            timesheetUpdated: true
          });
        })
        .catch((error) => {
          toast.update(currentToast, SAVE_TIMESHEET_TOAST_MESSAGES.error);
          return dispatch({ type: SAVE_TIMESHEET_FAILED, errorMessage: ['Error', error.message] });
        });
    }

    toast.update(currentToast, SAVE_TIMESHEET_TOAST_MESSAGES.error);
    return dispatch({ type: SAVE_TIMESHEET_FAILED, errorMessage: ['Error', 'Failed to save timesheet'] });
  };
}

function enrichEntriesWithProjectNamesWhenApplicable(timesheet, projects) {
  const timesheetClone = cloneDeep(timesheet);
  timesheetClone.days.forEach(d => {
    d.entries.forEach(e => {
      if (e.projectId && !e.projectName) {
        e.projectName = projects.find(p => p.projectId === e.projectId).projectName;
      }
    });
  });

  return timesheetClone;
}

export function submitTimesheet(timesheet) {
  return async function(dispatch, getState) {
    dispatch({ type: SUBMIT_TIMESHEET });
    currentToast = toast.loading(
      SUBMIT_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      SUBMIT_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      await handleSaveTimesheet(timesheet);
      try {
        await handleSubmitTimesheet(timesheet);
        toast.update(currentToast, SUBMIT_TIMESHEET_TOAST_MESSAGES.success);
        return dispatch({
          type: SUBMIT_TIMESHEET_SUCCESS,
          timesheet: timesheet,
          currentUser: getState().auth.user,
          timesheetUpdated: true
        });
      } catch (error) {
        toast.update(currentToast, SUBMIT_TIMESHEET_TOAST_MESSAGES.error);
        return dispatch({ type: SUBMIT_TIMESHEET_FAILED, error });
      }
    } catch (error) {
      toast.update(currentToast, SUBMIT_TIMESHEET_TOAST_MESSAGES.error);
      return dispatch({ type: SUBMIT_TIMESHEET_FAILED, error });
    }
  };
}

export function approveTimesheet(timesheet) {
  return async function (dispatch, getState) {
    dispatch({ type: APPROVE_OWN_TIMESHEET });
    currentToast = toast.loading(
      APPROVE_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      APPROVE_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      await handleSaveTimesheet(timesheet);
      try {
        await handleApproveTimesheet(timesheet);
        toast.update(currentToast, APPROVE_TIMESHEET_TOAST_MESSAGES.success);
        return dispatch({
          type: APPROVE_OWN_TIMESHEET_SUCCESS,
          timesheet: timesheet,
          currentUser: getState().auth.user,
          timesheetUpdated: true
        });
      } catch (error) {
        toast.update(currentToast, APPROVE_TIMESHEET_TOAST_MESSAGES.error);
        return dispatch({ type: APPROVE_OWN_TIMESHEET_FAILED, error });
      }
    } catch (error) {
      toast.update(currentToast, APPROVE_TIMESHEET_TOAST_MESSAGES.error);
      return dispatch({ type: APPROVE_OWN_TIMESHEET_FAILED, error });
    }
  };
}

export function downloadTimesheet(timesheet) {
  return async function(dispatch) {
    currentToast = toast.loading(
      DOWNLOAD_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      DOWNLOAD_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    dispatch({ type: DOWNLOAD_TIMESHEET });
    try {
      const response = await handleDownloadTimesheet(timesheet);
      fileService.handleFileDownload(response);
      toast.update(currentToast, DOWNLOAD_TIMESHEET_TOAST_MESSAGES.success);
      return dispatch({ type: DOWNLOAD_TIMESHEET_SUCCESS });
    } catch (error) {
      toast.update(currentToast, DOWNLOAD_TIMESHEET_TOAST_MESSAGES.error);
      return dispatch({ type: DOWNLOAD_TIMESHEET_FAILED, error });
    }
  };
}

export function downloadValidatedTimesheet(timesheet, publicLink) {
  return async function(dispatch) {
    dispatch({ type: DOWNLOAD_VALIDATED_TIMESHEET });
    currentToast = toast.loading(
      DOWNLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      DOWNLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      const response = await handleDownloadValidatedTimesheet(timesheet, publicLink);
      fileService.handleFileDownload(response);
      toast.update(currentToast, DOWNLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.success);
      return dispatch({ type: DOWNLOAD_TIMESHEET_SUCCESS });
    } catch (error) {
      toast.update(currentToast, DOWNLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.error);
      return dispatch({ type: DOWNLOAD_TIMESHEET_FAILED, error });
    }
  };
}

export function uploadTimesheet(file, timesheet) {
  return async function(dispatch) {
    dispatch({ type: UPLOAD_TIMESHEET });
    currentToast = toast.loading(
      UPLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      UPLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      await handleUploadTimesheet(file, timesheet);
      toast.update(currentToast, UPLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.success);
      return dispatch({ type: UPLOAD_TIMESHEET_SUCCESS });
    } catch (error) {
      toast.update(currentToast, UPLOAD_VALIDATED_TIMESHEET_TOAST_MESSAGES.error);
      return dispatch({ type: UPLOAD_TIMESHEET_FAILED, error });
    }
  };
}

export function autocompleteTimesheet(
  timesheet,
  type,
  amount,
  remarks,
  isFreeTextAllowed,
  contractStartDate,
  contractEndDate,
  isHomework
) {
  return {
    type: AUTOCOMPLETE_TIMESHEET,
    entryType: type,
    timesheet,
    amount,
    remarks,
    isFreeTextAllowed,
    contractStartDate,
    contractEndDate,
    isHomework
  };
}

export function addExpense(timesheet, dayIndex, entryIndex) {
  return { type: ADD_EXPENSE, timesheet, dayIndex, entryIndex };
}

export function removeExpense(timesheet, dayIndex, entryIndex, expenseIndex) {
  return { type: REMOVE_EXPENSE, timesheet, dayIndex, entryIndex, expenseIndex };
}

export function showExpenseModal(dayIndex) {
  return (dispatch) => {
    dispatch({ type: SHOW_EXPENSE_MODAL, dayIndex });
  };
}

export function setExpenseKey(timesheet, dayIndex, entryIndex, expenseId, key) {
  return { type: SET_EXPENSE_KEY, timesheet, dayIndex, entryIndex, expenseId, key };
}

export function setExpenseAmount(timesheet, dayIndex, entryIndex, expenseId, key, min, max) {
  return { type: SET_EXPENSE_AMOUNT, timesheet, dayIndex, entryIndex, expenseId, key, min, max };
}

export function setExpenseRemark(timesheet, dayIndex, entryIndex, expenseId, key) {
  return { type: SET_EXPENSE_REMARK, timesheet, dayIndex, entryIndex, expenseId, key };
}

export function setEntryHomework(timesheet, dayIndex, entryIndex, isHomework) {
  return { type: SET_ENTRY_HOMEWORK, timesheet, dayIndex, entryIndex, isHomework };
}

export function fetchHolidays(countryCode = 'BE') {
  return async (dispatch) => {
    dispatch({ type: FETCH_HOLIDAYS });
    try {
      const response = await getHolidays({ countryCode });
      return dispatch({ type: FETCH_HOLIDAYS_SUCCESS, holidays: response.data });
    } catch (error) {
      dispatch({ type: FETCH_HOLIDAYS_FAILED, error });
      showRequestFailedToast();
    }
  };
}

export function setTemporaryTimesheetSigner(timesheet, email) {
  return async (dispatch) => {
    dispatch({ type: SET_TEMP_TIMESHEET_SIGNER });
    try {
      await updateTemporaryTimesheetSigner(timesheet, email);
      return dispatch({ type: SET_TEMP_TIMESHEET_SIGNER_SUCCESS, timesheet, email });
    } catch (error) {
      dispatch({ type: SET_TEMP_TIMESHEET_SIGNER_FAILED, error });
      showRequestFailedToast();
    }
  };
}
