import React from 'react';
import { useSelector } from 'react-redux';
import { EMPLOYEE } from '../../constants/candidateTypes';
import { TEAM4TALENT, TALENT2TEST } from '../../constants/companies';
import useHasAdminClaim from '../common/customHooks/useHasAdminClaim';
import MenuItem from './MenuItem';
import { shouldBeAbleToSeeCreditsSelector, shouldBeAbleToSeeNews, shouldBeAbleToSeeTimesheets } from '../../redux/helperSelectors';

function Menu() {
  const { user } = useSelector((state) => state.auth);
  const hideMenu = useSelector((state) => state.system.hideMenu);
  // const hasCareerTracksClaim = userRoles.includes('t4tmyportal-careertrack');
  // const hasManageTracksClaim = userRoles.includes('t4tmyportal-managetracks');
  const hasAdminClaim = useHasAdminClaim();
  const shouldBeAbleToSeeCredits = useSelector((state) => shouldBeAbleToSeeCreditsSelector(state));
  const shouldBeAbleToSeeNewsTab = useSelector((state) => shouldBeAbleToSeeNews(state));
  const shouldBeAbleToSeeTimesheetsTab = useSelector((state) => shouldBeAbleToSeeTimesheets(state));

  const link = (
    <div>
      <span className={`link-text ${hideMenu ? 'hide' : ''}`}>
        Made with <span className="icon-gradient bg-love">love</span> by Team4Talent
      </span>
      <img
        className={`cropped-logo ${!hideMenu ? 'hide' : ''}`}
        src={require('../../assets/images/cropped-team4talent-logo.svg')}
      />
    </div>
  );

  return (
    <nav id="sidebar" className={hideMenu ? 'sidebar-small' : ''}>
      <ul className="list-unstyled components sidebar-menu">
        <MenuItem to="/" text="Home" exact icon="pe-7s-home" />
        <MenuItem to="/contracts" text="Contracts" icon="pe-7s-note2" />
        {shouldBeAbleToSeeTimesheetsTab &&
          <MenuItem to="/timesheets" text="Timesheets" icon="pe-7s-copy-file" />
        }
        <MenuItem to="/assets" text="Assets" icon="pe-7s-car" />
        <MenuItem to="/documents" text="Documents" icon="pe-7s-folder" />
        {shouldBeAbleToSeeCredits && <MenuItem to="/credits" text="Credits" icon="pe-7s-credit" />}
        {user.profile.candidate_type === EMPLOYEE && (user.profile.company === TEAM4TALENT || user.profile.company === TALENT2TEST) && (
            <MenuItem to="/usertracks" text="Career Tracks" icon="pe-7s-graph1" />
        )}
        {user.profile.candidate_type === EMPLOYEE && user.profile.company === TEAM4TALENT && (
            <MenuItem
              to="https://team4talent.sharepoint.com/sites/consultants/SitePages/CompetenceCenterHome.aspx"
              text="Knowledge Base"
              icon="pe-7s-science"
              external
            />
        )}
        <MenuItem to="/contacts" text="Contact" icon="pe-7s-notebook" />
        {shouldBeAbleToSeeNewsTab && <MenuItem to="/news" text="News" icon="pe-7s-news-paper" />}
        {hasAdminClaim && <MenuItem to="/users" text="Manage Users" icon="pe-7s-user" />}
      </ul>
      <a
        href="https://team4talent.be"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: 'inherit',
          position: 'fixed',
          bottom: 0,
          margin: '0 0 0.5em 0.5em',
        }}>
        {link}
      </a>
    </nav>
  );
}

export default Menu;
