import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '../common/Card';
import { fetchUsers, selectUser, setFilterValue } from '../../redux/users/actions';
import Table from '../common/Table';
import Loading from '../common/Loading';

function UsersListCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isFetchingUsers, triedFetchingUsers } = useSelector((state) => state.system);
  const { usersList, selectedUser } = useSelector((state) => state.users);
  const { skip, take, totalCount, filterValue } = useSelector((state) => state.users);
  const prevUser = useRef(selectedUser);

  useEffect(() => {
    if (prevUser.current !== selectedUser && selectedUser != null) {
      history.push('/contracts');
    }
  }, [history, selectedUser]);

  const handleChangeFilter = useCallback(
    (e) => {
      const filter = e.target.value;
      dispatch(setFilterValue(filter));
      dispatch(fetchUsers(0, take, filter.trim()));
    },
    [dispatch, take]
  );

  const handleChangePage = (pageIndex) => {
    dispatch(fetchUsers(pageIndex, take, filterValue));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(fetchUsers(0, event.target.value, filterValue));
  };

  const columns = (
    <>
      <th className="col-lg-3">First name</th>
      <th className="col-lg-4">Last name</th>
      <th className="col-lg-3">Tenant</th>
      <th className="col-lg-2"></th>
    </>
  );

  const mappedUsers = useMemo(
    () =>
      usersList != null &&
      usersList.map((user, i) => {
        return (
          <tr key={i} className="clickable" onClick={() => dispatch(selectUser(user))}>
            <td className="user-name">{user.firstName}</td>
            <td className="user-name">{user.lastName}</td>
            <td className="user-name">{user.tenantName}</td>
            <td className="user-selected">
              {selectedUser.managingOtherUser && selectedUser.myportalUserId == user.id && (
                <span className={'t4t-badge bg-green-blue'}>Stop managing user</span>
              )}
            </td>
          </tr>
        );
      }),
    [dispatch, usersList, selectedUser]
  );

  return (
    <>
      <Card
        title="Users"
        icon="pe-7s-user"
        iconGradient="bg-green-blue-top"
        contentClass={'card-table-content'}>
        <div className="container-fluid pl-0 pr-0">
          <DebounceInput
            type="text"
            value={filterValue}
            className="form-control"
            placeholder="Search for users"
            onChange={handleChangeFilter}
            debounceTimeout="250"
            autoFocus={true}
          />
          {isFetchingUsers || !triedFetchingUsers ? (
            <Loading message="Hang on, we're loading users." className="mt-5" />
          ) : mappedUsers.length > 0 ? (
            <Table
              columns={columns}
              data={mappedUsers}
              totalCount={totalCount}
              skip={skip}
              take={take}
              changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
              changeRowsPerPageHandler={handleChangeRowsPerPage}
            />
          ) : (
            <div className={usersList.length === 0 && filterValue && 'filter-no-data'}>
              <i className="pe-7s-help1 icon-gradient bg-green-blue-top mr-1" />
              No users found.
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

export default UsersListCard;
