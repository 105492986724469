import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageNotFound from '../pages/NotFound';
import Timesheets from '../pages/Timesheets';
import { News } from '../pages/News/News';
import Users from '../pages/Users';
import Usertracks from '../pages/Usertracks';
import Documents from '../pages/Documents';
import Transactions from '../pages/Transactions';
import ContactsPage from '../pages/Contacts';
import Assets from '../pages/Assets';
import Contracts from '../pages/Contracts';
import Home from '../pages/Home';
import { Route, Switch } from 'react-router-dom';
import Header from './menu/Header';
import Menu from './menu/Menu';
import { useAuth } from 'react-oidc-context';
import {
  shouldBeAbleToSeeCreditsSelector,
  shouldBeAbleToSeeNews,
  shouldBeAbleToSeeTimesheets,
  shouldBeAbleToSeeUsertracksSelector
} from '../redux/helperSelectors';
import { dispatchSetUser } from '../redux/auth/actions';
import multibrandingService from '../service/multibrandingService';
import { setBrandingColor } from '../redux/system/actions';
import { fetchContractsByUserId } from '../redux/contracts/actions';
import { fetchHolidays, fetchTimesheetsByUserId } from '../redux/timesheets/actions';
import { fetchProjectsByUserId } from '../redux/projects/actions';
import { fetchCreditsByUserId } from '../redux/transactions/actions';
import { fetchTenants } from '../redux/tenants/actions';
import { fetchUserTypes } from '../redux/users/actions';


const SKIP = 0;
const TAKE = 20;
const Authenticated = () => {
  const dispatch = useDispatch();
  const { isShowingModal } = useSelector((state) => state.system);
  const shouldBeAbleToSeeCredits = useSelector((state) => shouldBeAbleToSeeCreditsSelector(state));
  const shouldBeAbleToSeeUsertracks = useSelector((state) => shouldBeAbleToSeeUsertracksSelector(state));
  const shouldBeAbleToSeeNewsTab = useSelector((state) => shouldBeAbleToSeeNews(state));
  const shouldBeAbleToSeeTimesheetsTab = useSelector((state) => shouldBeAbleToSeeTimesheets(state));
  const auth = useAuth();
  const user = auth.user;
  const selectedUser = useSelector((state) => state.users.selectedUser);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(async () => {
      const user = await auth.signinSilent();
      dispatch(dispatchSetUser(user));
    });
  }, [auth, auth.events, auth.signinSilent, dispatch]);

  useEffect(() => {
    const brandingColor = multibrandingService.applyBranding(user.profile.company);
    dispatch(setBrandingColor(brandingColor));
  }, [user, dispatch]);

  useEffect(() => {
    const { myportalUserId, managingOtherUser } = selectedUser;
    dispatch(fetchContractsByUserId(myportalUserId, managingOtherUser, SKIP, TAKE));
    dispatch(fetchTimesheetsByUserId(myportalUserId, managingOtherUser, SKIP, TAKE));
    dispatch(fetchProjectsByUserId(myportalUserId));
    dispatch(fetchCreditsByUserId(myportalUserId));
  }, [selectedUser, dispatch]);

  useEffect(() => {
    dispatch(fetchHolidays());
    dispatch(fetchTenants());
    dispatch(fetchUserTypes());
  }, [dispatch]);

  return (
    <>
        <div>
          <Header />
          <div className="wrapper wrapper-stretch d-flex">
            <Menu />
            <div id="content">
              <Switch>
                <Route path="/" exact component={Home} />
                {shouldBeAbleToSeeTimesheetsTab && <Route path="/timesheets" component={Timesheets} />}
                <Route path="/contracts" component={Contracts} />
                <Route path="/assets" component={Assets} />
                <Route path="/contacts" component={ContactsPage} />
                {shouldBeAbleToSeeCredits && <Route path="/credits" component={Transactions} />}
                <Route path="/documents" component={Documents} />
                {shouldBeAbleToSeeUsertracks && <Route path="/usertracks" component={Usertracks} />}
                <Route path="/users" component={Users} />
                {shouldBeAbleToSeeNewsTab && <Route path="/news" exact component={News} />}
                {shouldBeAbleToSeeTimesheetsTab &&
                  <Route path={`/timesheets/consultant/:contractId/`} component={Timesheets} />}
                <Route component={PageNotFound} />
              </Switch>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ display: isShowingModal ? 'block' : 'none' }} />
      </>
  );
};


export default Authenticated;
