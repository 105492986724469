import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../../common/Card';
import NoContractsCard from './NoContractsCard';
import ContractListRow from './ContractListRow';
import ContractListRowDetails from './ContractListRowDetails';
import { fetchContractsByUserId } from '../../../redux/contracts/actions';
import Table from '../../common/Table';
import Loading from '../../common/Loading';
import { stopLoadingSelector } from '../../../redux/helperSelectors';
import { sortContractsByDateAndStatus } from '../../common/SortingService';

function ContractsListCard({ onlyActive, collapseRows }) {
  const dispatch = useDispatch();
  const { selectedUser, nameToDisplay } = useSelector((state) => state.users);
  const { contractsList: contracts, skip, take, totalCount } = useSelector((state) => state.contracts);
  const { isFetchingProfile, isFetchingContracts, triedFetchingContracts } = useSelector((state) => state.system);
  const stopLoading = useSelector((state) => stopLoadingSelector(state));

  const filteredContracts = useMemo(
    () => (onlyActive ? contracts.filter((c) => c.isActive) : contracts),
    [contracts, onlyActive]
  );

  const sortedContracts = useMemo(() => {
    return sortContractsByDateAndStatus(filteredContracts);

  }, [filteredContracts]);

  const title = useMemo(() => `${nameToDisplay} ${onlyActive ? 'Active' : ''} Contracts`, [nameToDisplay, onlyActive]);

  const handleChangePage = (pageIndex) => {
    dispatch(fetchContractsByUserId(selectedUser.myportalUserId, selectedUser.managingOtherUser, pageIndex, take));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      fetchContractsByUserId(selectedUser.myportalUserId, selectedUser.managingOtherUser, 0, event.target.value)
    );
  };

  const columns = (
    <>
      <th />
      {/* <th>Contract</th> */}
      <th>Company</th>
      <th>Client</th>
      <th>Location</th>
      <th>State</th>
      <th>Start</th>
      <th>End</th>
    </>
  );

  const mappedContracts = useMemo(
    () =>
      sortedContracts.map((contract, i) => {
        const key = `${contract.contractId}:${contract.company}`;
        return (
          <React.Fragment key={key}>
            <ContractListRow contract={contract} i={i} />
            <tr className={'collapse ' + (collapseRows ? 'hidden' : 'show')} id={`collapse-${i}`}>
              <td colSpan="12">
                <ContractListRowDetails contract={contract} />
              </td>
            </tr>
          </React.Fragment>
        );
      }),
    [collapseRows, sortedContracts]
  );

  return (isFetchingContracts || !triedFetchingContracts || isFetchingProfile) && !stopLoading ? (
    <Card title={title} icon="pe-7s-note2" iconGradient="bg-green-blue-top" contentClass="no-current-card">
      <Loading message="Hang on, we're loading your contracts." />
    </Card>
  ) : filteredContracts.length ? (
    <Card title={title} icon="pe-7s-note2" iconGradient="bg-green-blue-top" contentClass="card-table-content">
      <Table
        columns={columns}
        data={mappedContracts}
        totalCount={totalCount}
        skip={skip}
        take={take}
        changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
        changeRowsPerPageHandler={handleChangeRowsPerPage}
        showPagination={!onlyActive}
      />
    </Card>
  ) : (
    <NoContractsCard title={title} onlyActive={onlyActive} />
  );
}

ContractsListCard.propTypes = {
  collapseRows: PropTypes.bool,
  onlyActive: PropTypes.bool,
};

ContractsListCard.defaultProps = {
  onlyActive: false,
  collapseRows: true,
};

export default memo(ContractsListCard);
