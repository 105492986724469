import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TablePagination from './TablePagination';

function Table({
  columns,
  data,
  showPagination = true,
  totalCount,
  skip,
  take,
  changePageHandler,
  changeRowsPerPageHandler,
}) {
  return (
    <>
      <table className="table">
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody>{data}</tbody>
      </table>
      {showPagination && (
        <TablePagination
          component="div"
          count={totalCount}
          page={skip}
          rowsPerPage={take}
          rowsPerPageOptions={[10, 20, 50]}
          showFirstButton={true}
          showLastButton={true}
          onPageChange={changePageHandler}
          onRowsPerPageChange={changeRowsPerPageHandler}
        />
      )}
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.object,
  data: PropTypes.array,
  showPagination: PropTypes.bool,
  totalCount: PropTypes.number,
  skip: PropTypes.number,
  take: PropTypes.number,
  changePageHandler: PropTypes.func,
  changeRowsPerPageHandler: PropTypes.func,
};

export default memo(Table);
